<template>
    <div class="container-xl">
      <div class="card">
        <div class="card-header justify-content-between">
          <div class="card-title">{{ $route.name }} Form</div>
        </div>
        <div class="card-body col-lg-12">
          <b-form @submit.prevent="doSave">
            <b-form-group label="Amount">
              <el-input
                v-model="v$.form.nominal.$model"
                placeholder="Input topup amount"
                type="text"/>
              <b-form-invalid-feedback :state="!v$.form.nominal.$invalid" v-if="v$.form.nominal.$errors[0]">
                <span> {{ v$.form.nominal.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Vendor">
              <el-select
                class="w-full"
                v-model="v$.form.vendor_id.$model"
                placeholder="Select Vendor that get topup"
                @change="changeHandlerVendorMethod">
                <el-option v-for="(item, index) in list" :value="item.vendor_id" :key="index" :label="item.vendor_name">
                  <div class="flex" style="max-height: 70px">
                    <span class="ml-2">{{ item.vendor_name }}</span>
                  </div>
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.vendor_id.$invalid" v-if="v$.form.vendor_id.$errors[0]">
                <span> {{ v$.form.vendor_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
              <small v-if="last_balance">
                <i class="fe fe-alert-triangle mr-2" aria-hidden="true">{{last_balance}}</i>
              </small>
            </b-form-group>

            <b-form-group label="Top Up Date">
              <el-date-picker
                :picker-options="datePickerOptions"
                class="w-full"
                v-model="v$.form.topup_at.$model"
                type="date"
                placeholder="Pick date"/>
              <b-form-invalid-feedback :state="!v$.form.topup_at.$invalid" v-if="v$.form.topup_at.$errors[0]">
                <span> {{ v$.form.topup_at.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>

          </b-form>
        </div>
        <div class="card-footer">
          <div class="d-flex flex-row float-right">
            <el-button @click="doSave" :loading="loading" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="$router.back()" size="small" class="mr-2">Cancel</el-button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';
import {
  POST_TOPUPS,
  GET_VENDORS,
  GET_VENDORS_BALANCE,
} from '@/store/modules/topups';
import moment from 'moment';

export default {
  name: 'Topup',
  metaInfo: {
    title: 'Topup',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      loading: false,
      list: [],
      moment,
      form: {
        nominal: '',
        vendor_id: '',
        topup_at: '',
      },
      err_show: false,
      isLoading: false,
      last_balance: '',
      err_msg: this.$t('response_error.general_error'),
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        nominal: {
          required,
          numeric,
        },
        vendor_id: {
          required,
        },
        topup_at: {
          required,
        },
      },
    };
  },
  computed: {
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getVendorList();
    this.v$.form.$touch();
    loader.hide();
  },
  methods: {
    async getVendorList() {
      this.loading = true;
      await this.$store.dispatch(GET_VENDORS, {
      }).catch(() => {});
      const { rows, count } = this.$store.getters.vendors_topup;
      this.total_rows = count || 0;
      this.list = rows;
      this.loading = false;
    },
    async doSave() {
      this.v$.form.$touch();
      if (this.v$.form.$error) {
        for (const d in this.statusForm) {
          if (d) this.statusForm[d] = true;
        }
        return;
      }
      this.$parent.showLoader();
      this.isLoading = true;
      this.err_show = false;
      // this.form.topup_at = moment().format('YYYY-MM-DD');
      await this.$store.dispatch(POST_TOPUPS, this.form).then(() => {
        this.form = {
          nominal: '',
          vendor_id: '',
          topup_at: '',
        };
        this.last_balance = '';
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Topup Success',
        });
      }).catch((err) => {
        console.log('catch');
        console.log(err);
        if (err.response?.data?.error) {
          const k = `response_error.${err.response?.data?.error.title}`;
          this.err_msg = this.$t(k);
          if (this.err_msg === k) {
            this.err_msg = err.response?.data?.error.message;
          }
        }
        this.err_show = true;
      });
      this.$parent.hideLoader();
      this.isLoading = false;
    },
    async changeHandlerVendorMethod(value) {
      this.last_balance = '';
      const picked = this.list.find((o) => o.vendor_id === value);

      this.loading = true;
      await this.checkVendorBalance(picked.vendor_code);
      this.loading = false;
    },

    async checkVendorBalance(value) {
      await this.$store.dispatch(GET_VENDORS_BALANCE, {
        vendor_code: value,
      }).then(() => {
        const { rows } = this.$store.getters.vendors_last_balance;
        this.last_balance = `Current balance: ${rows.balance}`;
      }).catch((err) => {
        if (err.response?.data?.error) {
          const k = `response_error.${err.response?.data?.error.title}`;
          this.last_balance = this.$t(k);
          if (this.err_msg === k) {
            this.last_balance = err.response?.data?.error.message;
          }
        }
      });
    },
  },
};
</script>
